<template>
  <div class="traitements-wrapper" id="laser">
    <span class="rbs-title">Traitements Laser</span>
    <img src="../assets/images/Candella_1_small.jpg" class="img-fluid" alt="" />
    <div class="img-title">Épilation laser</div>
    <span class="img-text">
      Les lasers Gentle Pro™ sont des appareils puissants et à la pointe de la
      technologie qui assurent une réduction permanente des poils rapidement et
      efficacement. Les lasers Gentle Pro fonctionnent en ciblant sélectivement
      le follicule pileux avec des impulsions intenses d’énergie lumineuse
      induisant une destruction thermique, sans endommager les tissus
      environnants. Une protection épidermique est assurée par le système de
      refroidissement Dynamic Cooling Device™ (DCD™) qui émet un froid
      cryogénique sur la peau avant chaque impulsion laser. Ce système de
      refroidissement permet une meilleure protection de l’épiderme et garantit
      une séance confortable. Les appareils Gentle Pro™ peuvent traiter de
      nombreuses autres indications cutanées autres que l’épilation laser.
      Demandez à votre praticien si le traitement laser Gentle Pro™ répond à vos
      besoins.
      <br />
      A quoi puis-je m’attendre ?
      <br />
      Les poils ont tous le même cycle de vie mais ne vivent pas les même phases
      du cycle au même moment. C’est pour cette raison que plusieurs séances
      sont toujours nécessaires. Le nombre de séances et l’espacement entre
      chacune d’elles seront déterminés par votre praticien.
      <br />
      Les sensations ressenties lors de l’épilation au laser sont communément
      décrites comme similaires à un élastique claqué contre la peau. Une
      anesthésie locale peut être utilisée avant le traitement si besoin.
      <br />
      L’exposition au soleil dans les 4 à 6 semaines qui précèdent une épilation
      au laser est à proscrire (une peau bronzée contre-indique le traitement).
      L’exposition au soleil 1 mois après la séance est également à éviter.
      L’épilation à la cire ou à la pince à épiler 4 à 6 semaines avant la
      séance et entre les séances est contre-indiquée.
      <br />
      Après la séance, une compresse froide peut être appliquée. Une rougeur ou
      un gonflement peut apparaître après le traitement et disparaissent
      spontanément en quelques heures. Les effets secondaires possibles sont
      rares mais doivent être discutés avec votre médecin.
      <br />
      Votre praticien vous fournira toutes les instructions nécessaires au bon
      déroulement de votre traitement, en tout sécurité.
    </span>

    <img src="../assets/images/Candella_2_small.jpg" class="img-fluid" alt="" />
    <div class="img-title">Lésions vasculaires</div>
    <span class="img-text">
      Les lasers Gentle Pro™ sont des appareils puissants et à la pointe de la
      technologie délivrant de façon ciblée de courtes impulsions d’énergie dans
      la peau. L’énergie émise est absorbée de façon ciblée par les vaisseaux
      sanguins. Cette émission sélective d’énergie laser détruit les vaisseaux
      superficiels indésirables, éliminant ainsi les lésions inesthétiques.
      Avant et après chaque impulsion laser, l’appareil Gentle Pro™ pulvérise un
      froid cryogénique sur la peau, pour une meilleure protection de
      l’épiderme.
      <br />
      A quoi puis-je m’attendre ?
      <br />
      Le nombre de séances nécessaires varie d’un patient à l’autre et en
      fonction du type de lésion à traiter.
      <br />
      La durée de chaque séance dépendra de la zone à traiter.
      <br />
      Les lasers Gentle Pro™ sont dotés d’un système de refroidissement cutané
      unique. La plupart des patients trouvent donc les traitements Gentle Pro™
      extrêmement tolérables. Certaines personnes pourront toutefois ressentir
      une sensation similaire à un élastique claqué contre la peau.
      <br />
      Vous pourrez reprendre vos activités normales après chaque séance.
      Toutefois il vous sera recommandé d’éviter l’exposition au soleil ou
      l’usage de nettoyants abrasifs sur la zone traitée pendant quelques jours.
      <br />
      Après la séance, une compresse froide peut être appliquée. Une rougeur ou
      un gonflement peut apparaître après le traitement et disparaissent
      spontanément en quelques heures. Les effets secondaires possibles sont
      rares mais doivent être discutés avec votre médecin.
      <br />
      Votre praticien vous fournira toutes les instructions nécessaires au bon
      déroulement de votre traitement, en tout sécurité.
    </span>

    <img src="../assets/images/Candella_3_small.jpg" class="img-fluid" alt="" />
    <div class="img-title">Lésions pigmentaires</div>
    <span class="img-text">
      Les lasers Gentle Pro™ sont des appareils puissants et à la pointe de la
      technologie, délivrant de façon ciblée de courtes impulsions d’énergie
      dans la peau. L’énergie émise est absorbée par les pigments ciblés
      (mélanine) qui forment la lésion indésirable. Cette émission sélective
      d’énergie laser détruit l’excès de pigment et atténue la lésion, tout en
      laissant les tissus environnants intacts.
      <br />
      A quoi puis-je m’attendre ?
      <br />
      Le nombre de séances requises varie en fonction des lésions à traiter mais
      généralement deux à quatre séances sont nécessaires.
      <br />
      Certaines personnes ressentent un léger inconfort à chaque impulsion
      laser, une sensation similaire à un élastique claqué contre la peau.
      <br />
      Vous pourrez reprendre vos activités normales après chaque séance.
      Toutefois il vous sera recommandé d’éviter l’exposition au soleil ou
      l’usage de nettoyants abrasifs sur la zone traitée pendant quelques jours.
      <br />
      Après la séance, une compresse froide peut être appliquée. Une rougeur ou
      un gonflement peut apparaître après le traitement et disparaissent
      spontanément en quelques heures. Les effets secondaires possibles sont
      rares mais doivent être discutés avec votre médecin. Vous devez toujours
      suivre les instructions spécifiques de votre médecin.
      <br />
      Votre praticien vous fournira toutes les instructions nécessaires au bon
      déroulement de votre traitement, en tout sécurité.
    </span>

    <img src="../assets/images/Candella_4_small.jpg" class="img-fluid" alt="" />
    <div class="img-title">Réduire vos rides</div>
    <span class="img-text">
      Les lasers Gentle Pro™ sont des appareils puissants et à la technologie de
      pointe qui délivrent de courtes impulsions d’énergie dans la peau. Ces
      impulsions sont généralement délivrées très rapidement sur la zone
      traitée, chauffant lentement et doucement la peau jusqu’à ce que la
      température thérapeutique soit atteinte. L’énergie laser émise stimule la
      production de collagène, améliorant l’apparence des rides depuis
      l’intérieur.
      <br />
      A quoi puis-je m’attendre ?
      <br />
      Le nombre de séances nécessaires varie d’un patient à l’autre.
      <br />
      Vous pourrez reprendre vos activités normales après chaque séance mais
      vous pourrez recevoir des conseils spécifiques, comme éviter le soleil ou
      les nettoyants abrasifs sur la peau traitée pendant quelques jours.
      <br />
      Après la séance, une compresse fraîche ou un pack de gel peut être
      appliqué. Si vous présentez une rougeur ou un gonflement après le
      traitement, cela devrait disparaître en quelques heures. Les effets
      secondaires permanents sont rares mais doivent être discutés avec votre
      praticien.
      <br />
      Vous devez toujours suivre les instructions spécifiques de votre médecin.
    </span>
  </div>
</template>

<script>
export default {
  name: 'TraitmentsLaser'
}
</script>

<style lang="scss" scoped>
@import '../custom';
@import '../mixins';

.traitements-wrapper {
  display: flex;
  max-width: 100%;
  flex-flow: column wrap;
  justify-content: left;
  text-align: center;
  color: $text-dark;

  .img-fluid {
    width: 100%;
    height: auto;
    padding: 0em 10em;
    margin: auto;
    background-size: cover;
  }

  .img-title {
    color: $text-green;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    padding-top: 2em;
  }
  .img-text {
    min-width: 20em;
    padding: 1em 5em 8em 5em;
    text-align: left;
    color: $text-dark;
    font-weight: 400;
  }
}

@include media-breakpoint-down(lg) {
  .traitements-wrapper {
    .img-fluid {
      padding: 0em 5em;
    }
  }
}

@include media-breakpoint-down(md) {
  .traitements-wrapper {
    .img-fluid {
      padding: 0em 3em;
    }
  }
}

@include media-breakpoint-down(sm) {
  .traitements-wrapper {
    .img-fluid {
      width: 100%;
      height: auto;
      padding: 0em 1em;
      margin: auto;
      background-size: cover;
    }
    .img-text {
      padding: 1em 1em 4em 1em;
    }
  }
}
</style>
